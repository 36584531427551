<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 09:29:53
 * @LastEditTime: 2020-05-06 13:30:13
 -->
<template>
	<div v-loading="loading">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					type="year"
					v-model="searchForm.year"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
					:clearable="false"
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList" icon="el-icon-search">查询</el-button>
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting">设置</el-button>
			<el-button type="primary" size="small" @click="_export" style="float: right" icon="el-icon-download">导出</el-button>
		</el-form>
		<el-table :data="list" stripe :row-class-name="_rowClassName">
			<el-table-column align="center" fixed prop="name" width="200" label="费用项目（单位：元）"></el-table-column>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :label="col.label" align="center"></el-table-column>
			<el-table-column prop="yearTotalMoney" label="年度总计" align="center"></el-table-column>
			<el-table-column prop="yearProportion" label="年度占比" width="150" align="center"></el-table-column>
		</el-table>
		<DrawerCon :visible.sync="drawer" :listNameArr="['wagesConfigIds', 'companyConfigIds']" :mixAble="1" @updateList="_updateList"></DrawerCon>
	</div>
</template>
<script>
import DrawerCon from '../../components/drawerCon';
import { costListMonth } from '@/api/overview';
export default {
	props: ['yearNew'],
	components: { DrawerCon },
	data() {
		return {
			loading: false,
			drawer: false,
			searchForm: {
				year: '',
				wagesConfigIds: [],
				companyConfigIds: []
			},
			columns: [
				{ label: '1月', prop: 'january' },
				{ label: '2月', prop: 'february' },
				{ label: '3月', prop: 'march' },
				{ label: '4月', prop: 'april' },
				{ label: '5月', prop: 'may' },
				{ label: '6月', prop: 'june' },
				{ label: '7月', prop: 'july' },
				{ label: '8月', prop: 'august' },
				{ label: '9月', prop: 'september' },
				{ label: '10月', prop: 'october' },
				{ label: '11月', prop: 'november' },
				{ label: '12月', prop: 'december' }
			],
			list: []
		};
	},
	methods: {
		_getList() {
			for (const key in this.searchForm) {
				if (this.searchForm[key] === '' || this.searchForm[key].length == 0) {
					return false;
				}
			}
			this.loading = true;
			costListMonth(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res;
			});
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/month/cost/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		},
		_rowClassName({ rowIndex }) {
			if (rowIndex == 0) {
				return 'firstColor';
			}
		}
	},
	mounted() {
		const nowyear = new Date().getFullYear();

		this.searchForm.year = String(this.yearNew || nowyear);
	},
	watch: {
		'searchForm.year': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.wagesConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.companyConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
::v-deep.el-table__row.firstColor {
	color: #ff7371;
}
</style>
