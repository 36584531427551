<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-23 22:28:08
 * @LastEditTime: 2020-05-06 14:09:06
 -->
<template>
	<el-drawer class="moryeDrawer" v-bind="$attrs" v-on="$listeners" size="40%" :with-header="false">
		<div class="moryeDrawer_container">
			<div class="drawersettingHeader">
				<el-row :gutter="20">
					<el-col :span="8">
						<el-link icon="el-icon-arrow-left" @click="$emit('update:visible', false)">返回</el-link>
					</el-col>
					<el-col :span="8" style="text-align: center">成本纬度设置</el-col>
					<el-col :span="8" style="text-align: right">
						<el-link icon="el-icon-refresh-right" @click="_initList">重置</el-link>
						<!-- <el-link icon="el-icon-copy-document" @click="_setAll">全选</el-link> -->
					</el-col>
				</el-row>
			</div>
			<div class="moryeDrawer_container_sc">
				<el-collapse v-model="activeNames">
					<el-collapse-item name="1">
						<template slot="title">
							<div class="home_title" style="border: 0; padding: 0; margin: 0; padding-left: 20px">个人纬度</div>
						</template>
						<div class="drawersetting">
							<div class="drawersetting_box">
								<div v-for="(item, index) in payrollConfig" :key="index" v-show="item.children.length > 0">
									<div>
										<div class="drawersetting_title">
											{{ item.typeName }}
											<el-link
												@click="_clickAll(listNameArr[0], item.children)"
												:type="_returnIndex2(listNameArr[0], item.children) ? 'primary' : ''"
												v-if="item.children.length > 3 && item.children.length < 10 && _returnMixed(listNameArr[0], item) < 0"
												class="drawersetting_title_btn"
												icon="el-icon-copy-document"
												:underline="false"
												>全选</el-link
											>
											<el-link
												@click="_mixAll(listNameArr[0], item)"
												v-if="mixAble && item.children.length > 1"
												:type="_returnMixed(listNameArr[0], item) >= 0 ? 'primary' : ''"
												class="drawersetting_title_btn"
												:underline="false"
											>
												<img
													src="../../../assets/button/组合@3x.png"
													width="14px"
													style="vertical-align: middle"
													v-if="_returnMixed(listNameArr[0], item) >= 0"
												/>
												<img
													src="../../../assets/button/组合-未选中@3x.png"
													width="14px"
													style="vertical-align: middle"
													v-else
												/>
												{{ _returnMixed(listNameArr[0], item) >= 0 ? '取消组合' : '组合' }}
											</el-link>
										</div>
										<div v-if="_returnMixed(listNameArr[0], item) < 0">
											<div
												v-for="(child, i) in item.children"
												:key="i"
												style="display: inline-block; margin: 0 10px 10px 0px; padding: 0px 0 10px"
											>
												<el-button
													:type="_returnIndex(listNameArr[0], child.id) ? 'primary' : ''"
													:style="
														!_returnIndex(listNameArr[0], child.id)
															? 'background-color: #F5F5F5;color: #606266;border:none'
															: ''
													"
													size="small"
													@click="_btnClick(child.id, listNameArr[0])"
													>{{ child.typeName }}</el-button
												>
											</div>
										</div>
									</div>
								</div>
								<div v-if="annualBonusNew != undefined && annualBonusNew != null">
									<div class="drawersetting_title">年终奖</div>
									<el-row>
										<el-button :type="annualBonusNew ? 'primary' : ''" size="small" @click="_annualBonusNewBtn">年终奖</el-button>
									</el-row>
								</div>
							</div>
						</div>
					</el-collapse-item>
					<el-collapse-item name="2" v-if="listNameArr.length > 1">
						<template slot="title">
							<div class="home_title" style="border: 0; padding: 0; margin: 0; padding-left: 20px">公司纬度</div>
						</template>
						<div class="drawersetting">
							<div class="drawersetting_box">
								<div v-for="(item, index) in costConfig" :key="index" v-show="item.children.length > 0">
									<div>
										<div class="drawersetting_title">
											{{ item.typeName }}
											<el-link
												@click="_clickAll(listNameArr[1], item.children)"
												:type="_returnIndex2(listNameArr[1], item.children) ? 'primary' : ''"
												v-if="item.children.length > 3 && item.children.length < 10 && _returnMixed(listNameArr[1], item) < 0"
												class="drawersetting_title_btn"
												icon="el-icon-copy-document"
												:underline="false"
												>全选</el-link
											>
											<el-link
												@click="_mixAll(listNameArr[1], item)"
												v-if="mixAble && item.children.length > 1"
												:type="_returnMixed(listNameArr[1], item) >= 0 ? 'primary' : ''"
												class="drawersetting_title_btn"
												:underline="false"
											>
												<img
													src="../../../assets/button/组合@3x.png"
													width="14px"
													style="vertical-align: middle"
													v-if="_returnMixed(listNameArr[1], item) >= 0"
												/>
												<img
													src="../../../assets/button/组合-未选中@3x.png"
													width="14px"
													style="vertical-align: middle"
													v-else
												/>
												{{ _returnMixed(listNameArr[1], item) >= 0 ? '取消组合' : '组合' }}
											</el-link>
										</div>
										<section v-if="_returnMixed(listNameArr[1], item) < 0">
											<div
												v-for="(child, i) in item.children"
												:key="i"
												style="display: inline-block; margin: 0 10px 0 0; padding: 0px 0 10px"
											>
												<el-button
													:type="_returnIndex(listNameArr[1], child.id) ? 'primary' : ''"
													:style="
														!_returnIndex(listNameArr[1], child.id)
															? 'background-color: #F5F5F5;color: #606266;border:none'
															: ''
													"
													size="small"
													@click="_btnClick(child.id, listNameArr[1])"
													>{{ child.typeName }}</el-button
												>
											</div>
										</section>
									</div>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
	</el-drawer>
</template>
<script>
import { costConfigDetail, payrollConfigDetail } from '@/api/overview';
export default {
	props: ['listNameArr', 'annualBonusNew', 'mixAble', 'limit'],
	data() {
		return {
			activeNames: ['1', '2'],
			payrollConfig: [],
			costConfig: [],
			wagesConfigIds: [],
			companyConfigIds: [],
			mixList: []
		};
	},
	methods: {
		_getCostConfigDetail() {
			costConfigDetail({}).then((res) => {
				delete res._responseStatusCode;
				this.costConfig = res;
			});
		},
		_getPayrollConfigDetail() {
			payrollConfigDetail({}).then((res) => {
				delete res._responseStatusCode;
				this.payrollConfig = res;
			});
		},
		_btnClick(id, list) {
			const _index = this[list].indexOf(id);

			if (_index < 0) {
				if (this._validateLength(1)) {
					return this.$message.error('纬度最多选10个');
				}
				this[list].push(id);
			} else {
				if (this[list].length == 1) {
					return this.$message.error('分类纬度至少选一个');
				}
				this[list].splice(_index, 1);
			}
			this.$emit('updateList', { listName: list, listDetail: this[list] });
		},
		_returnIndex(list, id) {
			const _index = this[list].indexOf(id);

			if (_index < 0) {
				return false;
			}
			return true;
		},
		_returnIndex2(list, list2) {
			for (const key in list2) {
				const _index = this[list].indexOf(list2[key].id);

				if (_index >= 0) {
					return true;
				}
			}
			return false;
		},
		_clickAll(list, list2) {
			const _flag = this._returnIndex2(list, list2);

			if (_flag) {
				if (this[list].length - list2.length <= 0) {
					return this.$message.error('分类纬度至少选一个');
				}
				for (const key in list2) {
					this[list].forEach((item, index) => {
						if (item == list2[key].id) {
							this[list].splice(index, 1);
						}
					});
				}
			} else {
				const _count = list2.length;

				if (this._validateLength(_count)) {
					return this.$message.error('纬度最多选10个');
				}
				const _list = [];

				list2.forEach((item) => _list.push(item.id));
				this[list] = this[list].concat(_list);
			}
			this.$emit('updateList', { listName: list, listDetail: this[list] });
		},
		_validateLength(count) {
			const _count = Number(this.wagesConfigIds.length) + Number(this.companyConfigIds.length) + Number(count);

			if (this.limit) {
				if (this.annualBonusNew && this.annualBonusNew != undefined) {
					if (_count > 9) {
						return true;
					}
				} else if (_count > 10) {
					return true;
				}
			}
			return false;
		},
		_reSet() {
			this.listNameArr.forEach((item) => {
				this[item] = [];
				this.$emit('updateList', { listName: item, listDetail: [] });
			});
		},
		_setAll() {
			this._reSet();
			this.costConfig.forEach((item) => {
				item.children.forEach((child) => {
					this.companyConfigIds.push(child.id);
				});
			});
			this.payrollConfig.forEach((item) => {
				item.children.forEach((child) => {
					this.wagesConfigIds.push(child.id);
				});
			});
			this.listNameArr.forEach((item) => {
				this.$emit('updateList', { listName: item, listDetail: this[item] });
			});
		},
		_initList() {
			this.companyConfigIds = [this.costConfig[0].id, this.costConfig[1].children[0].id, this.costConfig[2].children[0].id];
			this.wagesConfigIds = [
				this.payrollConfig[0].children[0].id,
				this.payrollConfig[0].children[1].id,
				this.payrollConfig[0].children[2].id,
				this.payrollConfig[1].children[0].id,
				this.payrollConfig[1].children[1].id
			];
			this.listNameArr.forEach((item) => {
				this.$emit('updateList', { listName: item, listDetail: this[item] });
			});
		},
		_returnMixed(listName, classObj) {
			const _had = this[listName].indexOf(classObj.id);

			return _had;
		},
		_mixAll(listName, classObj) {
			const _index = this[listName].indexOf(classObj.id);

			if (_index > -1) {
				if (this[listName].length == 1) {
					return this.$message.error('分类纬度至少选一个');
				}
				this[listName].splice(_index, 1);
			} else {
				if (this._validateLength(1)) {
					return this.$message.error('纬度最多选10个');
				}
				const _list = [];

				for (const key in this[listName]) {
					let _index = true;
					const _id = this[listName][key];

					for (const keys in classObj.children) {
						if (classObj.children[keys].id == _id) {
							_index = false;
						}
					}
					_index ? _list.push(_id) : '';
				}
				_list.push(classObj.id);
				this[listName] = _list;
			}
			this.$emit('updateList', { listName, listDetail: this[listName] });
		},
		_annualBonusNewBtn() {
			if (!this.annualBonusNew) {
				if (this._validateLength(1)) {
					return this.$message.error('纬度最多选10个');
				}
			}
			this.$emit('update:annualBonusNew', !this.annualBonusNew);
		}
	},
	mounted() {
		Promise.all([costConfigDetail({}), payrollConfigDetail({})]).then((resList) => {
			delete resList[0]._responseStatusCode;
			delete resList[1]._responseStatusCode;
			resList[0].forEach((item) => (item.classType = `company${item.id}`));
			resList[1].forEach((item) => (item.classType = `person${item.id}`));
			this.costConfig = resList[0];
			this.payrollConfig = resList[1];
			// this._setAll();
			this._initList();
		});
		// this._getCostConfigDetail();
		// this._getPayrollConfigDetail()
	}
};
</script>
<style lang="scss" scoped>
.moryeDrawer {
	::v-deep.el-drawer__header {
		display: none;
	}
	::v-deep.el-drawer {
		overflow-y: scroll;
	}
	.moryeDrawer_container {
		height: 100%;
		display: flex;
		flex-direction: column;
		.drawersettingHeader {
			padding: 20px;
			border-bottom: 1px solid #c5cdda;
		}
		.moryeDrawer_container_sc {
			flex: 1;
			.drawersetting {
				padding: 20px;
				.drawersetting_box {
					padding-left: 20px;
					.drawersetting_title {
						font-size: 16px;
						color: #2b3642;
						margin-bottom: 20px;
						.drawersetting_title_btn {
							margin-left: 10px;
						}
					}
				}
			}
			.home_title {
				font-size: 16px;
				font-weight: bold;
				color: #2b3642;
				&:before {
					display: inline-block;
					width: 8px;
					height: 20px;
					background: #498df0;
					content: ' ';
					border-radius: 5px;
					vertical-align: text-bottom;
					margin-right: 8px;
				}
			}
		}
	}
}
::v-deep.el-button {
	white-space: break-spaces;
}
</style>
