<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 09:18:08
 * @LastEditTime: 2020-04-25 11:22:49
 -->
<template>
    <el-tabs v-model="activeName"  class="app-container">
        <el-tab-pane label="年度" name="1" lazy v-if="permissionControl('/v1/overview/year/cost/list')">
            <yearCon
                :contrastYearNew="$route.query.contrastYear || ''"
                :targetYearNew="$route.query.targetYear || ''"
            ></yearCon>
        </el-tab-pane>
        <el-tab-pane label="月度" name="2" lazy v-if="permissionControl('/v1/overview/month/cost/list')">
            <monthCon :yearNew="$route.query.year || ''"></monthCon>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import yearCon from './page/year';
import monthCon from './page/month';
export default {
    components: { yearCon, monthCon },
    data() {
        return {
            activeName: String(this.$route.query.type || 1)
        };
    }
};
</script>
<style lang="scss" scoped>

</style>
