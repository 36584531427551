/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-21 21:11:11
 * @LastEditTime: 2020-04-26 17:02:21
 */
import {
    // get,
    post
} from "./api";
//年度成本薪酬福利总体趋势
export const costStatistics = params => post('v1/overview/year/cost/statistics', { request: params });
//年度成本薪酬福利列表
export const costList = params => post('/v1/overview/year/cost/list', { request: params });
//月度成本薪酬福利总体趋势
export const costStatisticsMonth = params => post('/v1/overview/month/cost/statistics', { request: params });
//月度成本薪酬福利列表
export const costListMonth = params => post('/v1/overview/month/cost/list', { request: params });
//人力成本综合表
export const costIntegration = params => post('/v1/overview/manpower/cost/integration', { request: params });
//企业成本设置详情（默认登录之后就有权限） 成本纬度
export const costConfigDetail = params => post('/v1/enterprise/cost/config/detail', { request: params });
//工资设置查询(用来给其它页面调用) 成本纬度
export const payrollConfigDetail = params => post('/v1/payroll/config/detail', { request: params });
//统计维度列表
export const dimensionList = params => post('/v1/overview/dimension/list', { request: params });
//离职原因分析
export const quitReasonAnalysis = params => post('/v1/overview/quit/reason/analysis', { request: params });
//职级分析
export const levelAnalysis = params => post('/v1/overview/level/analysis', { request: params });
//部门分析
export const departmentAnalysis = params => post('/v1/overview/department/analysis', { request: params });
//层次分析 职类
export const classificationAnalysis = params => post('/v1/overview/classification/analysis', { request: params });
//重点岗位分析
export const jobAnalysis = params => post('/v1/overview/job/analysis', { request: params });
//性别分析
export const resignationAnalysis = params => post('/v1/overview/analysis/of/resignation', { request: params });
//受教育结构分析
export const educationAnalysis = params => post('/v1/overview/education/analysis', { request: params });
//年龄结构分析
export const ageAnalysis = params => post('/v1/overview/age/analysis', { request: params });
//司龄分析
export const employmentAgeAnalysis = params => post('/v1/overview/employment/age/analysis', { request: params });
//招聘分析
export const recruitAnalysis = params => post('/v1/overview/recruit/analysis', { request: params });
//人力资源异动分析
export const humanAnalysis = params => post('/v1/overview/analysis/human/resource/changes', { request: params });
//人力资源成本分析
export const humancostAnalysis = params => post('/v1/overview/human/resource/cost/analysis', { request: params });
//离职分析列表
export const analysisSummary = params => post('/v1/overview/quit/Analysis/summary', { request: params });