<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 09:21:44
 * @LastEditTime: 2020-05-06 13:29:50
 -->
<template>
	<div v-loading="loading">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					type="year"
					v-model="searchForm.contrastYear"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
					:clearable="false"
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					type="year"
					v-model="searchForm.targetYear"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
					:clearable="false"
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList" icon="el-icon-search">查询</el-button>
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting">设置</el-button>
			<el-button type="primary" size="small" @click="_export" style="float: right" icon="el-icon-download">导出</el-button>
		</el-form>
		<el-table :data="list" stripe :row-class-name="_rowClassName" @sort-change="_sortChange">
			<el-table-column label="费用项目（单位元）" prop="name" align="center"></el-table-column>
			<el-table-column :label="`${searchForm.contrastYear}年`" prop="contrastMoney" align="center"></el-table-column>
			<el-table-column label="年度占比" prop="contrastYearProportion" :sortable="'custom'" align="center"></el-table-column>
			<el-table-column :label="`${searchForm.targetYear}年`" prop="targetMoney" align="center"></el-table-column>
			<el-table-column label="年度占比" prop="targetYearProportion" :sortable="'custom'" align="center"></el-table-column>
			<el-table-column label="增长率" prop="growthRate" :sortable="'custom'" align="center"></el-table-column>
			<el-table-column label="增长率排序" prop="sort" align="center">
				<template slot-scope="scope">
					<span :style="{ color: _returnColor(scope.row.sort) }">{{ scope.row.sort }}</span>
				</template>
			</el-table-column>
		</el-table>
		<DrawerCon
			:visible.sync="drawer"
			:listNameArr="['wagesConfigIds', 'companyConfigIds']"
			@updateList="_updateList"
			:mixAble="1"
			:annualBonusNew.sync="searchForm.annualBonus"
		></DrawerCon>
	</div>
</template>
<script>
import DrawerCon from '../../components/drawerCon';
import { costList } from '@/api/overview';
export default {
	props: ['contrastYearNew', 'targetYearNew'],
	components: { DrawerCon },
	data() {
		return {
			loading: false,
			drawer: false,
			searchForm: {
				contrastYear: '',
				targetYear: '',
				annualBonus: true,
				wagesConfigIds: [],
				companyConfigIds: [],
				contrastYearProportionSort: '',
				targetYearProportionSort: '',
				growthRateSort: ''
			},
			list: []
		};
	},
	methods: {
		_getList() {
			for (const key in this.searchForm) {
				if (key != 'contrastYearProportionSort' && key != 'targetYearProportionSort' && key != 'growthRateSort') {
					if (this.searchForm[key] === '' || this.searchForm[key].length == 0) {
						return false;
					}
				}
			}
			this.loading = true;
			costList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res;
			});
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/year/cost/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		},
		_returnColor(sort) {
			switch (String(sort)) {
				case '1':
					return '#FFC457';
				case '2':
					return '#409EFF';
				case '3':
					return '#7ED321';
				case '--':
					return '#FF7371';
				default:
					return '#606266';
			}
		},
		_rowClassName({ row }) {
			if (row.name == '年度统计') {
				return 'firstColor';
			}
		},
		_sortChange({ prop, order }) {
			this.searchForm.contrastYearProportionSort = '';
			this.searchForm.targetYearProportionSort = '';
			this.searchForm.growthRateSort = '';
			if (prop == 'contrastYearProportion') {
				if (order == 'ascending') {
					this.searchForm.contrastYearProportionSort = 'asc';
				} else if (order == 'descending') {
					this.searchForm.contrastYearProportionSort = 'desc';
				}
			} else if (prop == 'targetYearProportion') {
				if (order == 'ascending') {
					this.searchForm.targetYearProportionSort = 'asc';
				} else if (order == 'descending') {
					this.searchForm.targetYearProportionSort = 'desc';
				}
			} else if (order == 'ascending') {
				this.searchForm.growthRateSort = 'asc';
			} else if (order == 'descending') {
				this.searchForm.growthRateSort = 'desc';
			}
			this._getList();
		}
	},
	mounted() {
		const nowyear = new Date().getFullYear();

		this.searchForm.contrastYear = String(this.contrastYearNew || nowyear - 1);
		this.searchForm.targetYear = String(this.targetYearNew || nowyear);
	},
	watch: {
		'searchForm.contrastYear': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.targetYear': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.annualBonus': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.wagesConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.companyConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
::v-deep.el-table__row.firstColor {
	color: #ff7371;
}
.tableWithoutHead {
	::v-deep.el-table__header-wrapper {
		display: none;
	}
}
</style>
